<template>
  <div class="appendix">
    <div v-html="content" class="content">
    </div>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, ref,
} from 'vue';
import httpApi from '../../utils/httpApi';
import text from './text';

export default {
  name: 'hkidrOtcr',
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      content: text.hkidrOtcr,
    };
  },
  methods: {
    async before() {
      const res = await this.$api.postChooseStepOne({
        ...this.submitForm,
        hkidr_otcr: '是',
      });
      if (res) {
        this.$router.back();
      }
    },
  },
  setup() {
    const isDisabled = ref(false);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', 'HKIDR and OTCR');
      store.commit('setStepsShow', false);

      const result = await httpApi.getChooseStepOne();
      const { data } = result;
      isDisabled.value = data.hkidr_otcr === '是';
      submitForm.value = data;
      httpApi.formLog(null, isDisabled);
    });
    return {
      isDisabled,
      submitForm,
    };
  },
};

</script>

<style lang="less">
  .appendix {
    margin-top: @space-2;
    text-align: left;

    .content {
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
